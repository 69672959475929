import { tryCatch } from '@thalesrc/js-utils';

import { getMedicinesById } from 'api/hs/examination/medicine';
import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { PrescriptionModel } from './prescription.model';
import { SavePrescriptionPayload, UpdatePrescriptionAfterEPrescription, UpdatePrescriptionPayload } from './prescription.payload';
import { PrescriptionResult } from './prescription.result';

export async function getPrescriptions(employeeId: string, examinationId: string): Promise<PrescriptionModel[]> {
  let res;

  try {
    if (examinationId) {
      res = await HSApi.get<PrescriptionResult[]>(`/prescriptions?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<PrescriptionResult[]>(`/prescriptions?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return await Promise.all(
    res?.data?.map(async item =>
      PrescriptionModel.fromServerData(item, await getMedicinesById(...item.prescriptionMedicines?.map(medicine => medicine.medicineId)))
    )
  );
}

export async function savePrescription(payload: SavePrescriptionPayload): Promise<PrescriptionModel> {
  const [error, res] = await tryCatch(HSApi.post<PrescriptionResult>(`/prescriptions`, payload));
  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error;
  }

  return PrescriptionModel.fromServerData(
    res.data,
    await getMedicinesById(...res.data.prescriptionMedicines?.map(medicine => medicine.medicineId))
  );
}

export async function updatePrescription({
  id,
  ...body
}: MakeRequired<Partial<UpdatePrescriptionPayload>, 'id'>): Promise<PrescriptionModel> {
  const [error, res] = await tryCatch(HSApi.put<PrescriptionResult>(`/prescriptions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return PrescriptionModel.fromServerData(
    res.data,
    await getMedicinesById(...res.data.prescriptionMedicines?.map(medicine => medicine.medicineId))
  );
}

export async function updatePrescriptionAfterCreatedEPrescription({
  id,
  ...body
}: MakeRequired<Partial<UpdatePrescriptionAfterEPrescription>, 'id'>): Promise<PrescriptionModel> {
  const [error, res] = await tryCatch(HSApi.patch<PrescriptionResult>(`/prescriptions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return PrescriptionModel.fromServerData(
    res.data,
    await getMedicinesById(...res.data.prescriptionMedicines?.map(medicine => medicine.medicineId))
  );
}

export async function deletePrescription(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/prescriptions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}

export async function previewPrescription(id: string) {
  const [err, res] = await tryCatch(HSApi.get(`/prescriptions/${id}/detail-view/export?type=HTML`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }
console.log(res.data);
  return res.data;
}

export async function getPrescriptionList(page = 0, size = 20, order: null | string, filters: any) {
  const payload = {
    filter: filters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post(`/prescriptions/pages`, payload);

  return res.data;
}
