import { Chip, Grid, Icon, IconButton } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ToggleButton } from '@material-ui/lab';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import Form, { FormRef } from 'Common/Form/Form';
import Avatar from 'TransactionsAndLists/EmployeeList/Avatar';

import { TrainingEmployeeEmployee } from '../../../../api/hs/trainings';
import { updateTrainingEmployees } from '../../../../api/hs/trainings/TrainingEmployee';
import Button from '../../../../Common/Button';
import { EmployeeSelectionCacheContext } from '../../../../Common/Form/EmployeeSelection/employee-selection.context';
import { SelectOption } from '../../../../Common/Form/Select';
import TextField from '../../../../Common/Form/TextField';
import ToggleButtonGroup from '../../../../Common/Form/ToggleButtonGroup';
import { AlertContext } from '../../../../context/alert.context';
import { useAsyncEffect, useTriggerRender } from '../../../../utils';
import { TrainingDetailContext } from '../completed.context';
import HSApi from "../../../../api/hs/HSApi";

interface OptionExtra {
  color: string;
}

const BASE_URL = process.env.REACT_APP_HS_API_BASE_URL;
const OPTIONS: (OptionExtra & SelectOption<number>)[] = [
  { text: 'Katıldı', value: 1, color: '#469789' },
  { text: 'Katılmadı', value: 0, color: '#eb4355' },
];

export default function ParticipantInfo() {
  const { loadEmployees, avatars, names } = useContext(EmployeeSelectionCacheContext);
  const { trainingDetail, reload } = useContext(TrainingDetailContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const triggerRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const [openParticipantInfo, setOpenParticipantInfo] = useState(false);

  const handleClick = () => {
    setOpenParticipantInfo(!openParticipantInfo);
  };

  useAsyncEffect(async () => {
    if (!trainingDetail?.trainingEmployees) {
      return;
    }
    await defer();

    loadEmployees(
      ...trainingDetail.trainingEmployees
        .filter(employee => (employee as TrainingEmployeeEmployee).employeeId)
        .map(employee => (employee as TrainingEmployeeEmployee).employeeId)
    );

    await defer();

    for (const employee of trainingDetail?.trainingEmployees) {
      formRef?.current?.methods?.setValue(
        (employee as TrainingEmployeeEmployee)?.employeeId + 'toogle',
        employee?.isParticipated === true ? 1 : 0
      );
      formRef?.current?.methods?.setValue((employee as TrainingEmployeeEmployee)?.employeeId + 'grade', employee?.grade);
      formRef?.current?.methods?.setValue((employee as TrainingEmployeeEmployee)?.employeeId + 'pretestGrade', employee?.pretestGrade);
    }

    await formRef?.current?.methods?.trigger();
  }, [trainingDetail?.trainingEmployees, openParticipantInfo]);

  const allIgnore = useCallback(() => {
    const data = formRef.current.methods.getValues();
    const dataItem = Object.entries(data).filter(([key]) => key.includes('toogle'));
    dataItem.map((item, index) => formRef.current.methods.setValue(item[0], 1));
    formRef.current.methods.trigger();
  }, []);

  const allNotIgnore = useCallback(() => {
    const data = formRef.current.methods.getValues();
    const dataItem = Object.entries(data).filter(([key]) => key.includes('toogle'));
    dataItem.map((item, index) => formRef.current.methods.setValue(item[0], 0));
    formRef.current.methods.trigger();
  }, []);

  const formChange = useCallback(
    values => {
      let changed = false;

      for (const { employeeId: id } of trainingDetail?.trainingEmployees) {
        if (values[id + 'toogle'] !== 1) {
          formRef?.current?.methods?.setValue(id + 'grade', null);
          formRef?.current?.methods?.setValue(id + 'pretestGrade', null);
          continue;
        }
        changed = true;
      }
      if (changed) {
        formRef?.current?.methods?.trigger();
      }
      triggerRender();
    },
    [trainingDetail, triggerRender]
  );

  const onSubmitFormData = useCallback(
    async formData => {
      const items = Object.entries(formData)
        .filter(([key]) => key.includes('toogle'))
        .reduce((acc, [key, value]) => {
          return [
            ...acc,
            {
              employeeId: key.substr(0, 36),
              isParticipated: value,
              pretestGrade: formData[key.substr(0, 36) + 'pretestGrade'] ? formData[key.substr(0, 36) + 'pretestGrade'] : null,
              grade: formData[key.substr(0, 36) + 'grade'] ? formData[key.substr(0, 36) + 'grade'] : null,
            },
          ];
        }, []);

      try {
        await updateTrainingEmployees(trainingDetail.id, items);
        showMessage('Katılımcıların bilgileri başarılı ile kayıt edildi.', 'success');
        reload();
        setOpenParticipantInfo(false);
      } catch (e) {
        showMessage('Kayıt sırasında hata oluştu.', 'error');
      }
    },
    [showMessage, trainingDetail, reload]
  );

  const certificates = useCallback((trainingId: string, employeeId: string, name: string) => {
    const fetchCertificate = async () => {
      try {
        const res = await HSApi.get(
            `/trainings/${trainingId}/training-employees/employees/${employeeId}/certificates/raw?type=PDF`,
            { responseType: 'blob' }
        );
        const file = res.data;
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = name + ' Katılım Sertifikası.pdf';
        document.body.appendChild(a); // Güvenlik için ekleme
        a.click();
        document.body.removeChild(a); // Temizlik için kaldırma
        URL.revokeObjectURL(url); // Bellek yönetimi için URL'yi serbest bırak
      } catch (error) {
        console.error("Error fetching certificate:", error);
      }
    };

    fetchCertificate();
  }, []);

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon className="icon-account-multiple" />
        </ListItemIcon>
        <ListItemText primary="Eğitimin Katılımcı Bilgileri" />
        {openParticipantInfo ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse style={{ paddingLeft: '15px', paddingTop: '10px' }} in={openParticipantInfo} timeout="auto" unmountOnExit>
        <Grid container>
          <Grid md={6} lg={6} xs={6} sm={6} className="text-left mt-2">
            <Typography className="ml-2" color="secondary">
              Eğitimin Toplam Süresi :{' '}
              {trainingDetail?.trainingDefinitions?.reduce((total, trainingDefinition) => {
                return total + trainingDefinition.durationInMinutes;
              }, 0)}{' '}
              dk
            </Typography>
          </Grid>

          <Grid md={6} lg={6} xs={6} sm={6} className="text-right mt-2">
            <Button className="mr-1" variant="outlined" onClick={allIgnore} color="primary">
              Tümü Katıldı
            </Button>
            <Button className="mr-2" variant="outlined" onClick={allNotIgnore} style={{ color: 'red' }}>
              Tümü Katılmadı
            </Button>
          </Grid>
        </Grid>
        <Form onSubmit={onSubmitFormData} onChange={formChange} ref={formRef}>
          <Grid container spacing={1}>
            {trainingDetail?.trainingEmployees?.map(employee => (
              <>
                <Grid className="mt-4" item md={3} lg={3}>
                  <Chip avatar={<Avatar url={avatars[employee.employeeId]} />} label={names[employee?.employeeId]} />
                </Grid>
                <Grid className="mt-2" item md={3} lg={3}>
                  <ToggleButtonGroup<number, OptionExtra>
                    name={employee.employeeId + 'toogle'}
                    options={OPTIONS}
                    defaultValue={0}
                    renderOption={({ color, selected, text, value }) => (
                      <ToggleButton
                        value={value}
                        className={selected ? 'text-white' : ''}
                        style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}>
                        {text}
                      </ToggleButton>
                    )}
                  />
                </Grid>
                <Grid style={{ display: 'flex', columnGap: '5px' }} className="mt-2" item md={6} lg={6}>
                  <TextField
                    type="number"
                    name={employee.employeeId + 'grade'}
                    label="Ön Değerlendirme"
                    disabled={formRef?.current?.value[employee.employeeId + 'toogle'] !== 1}
                  />
                  <TextField
                    type="number"
                    name={employee.employeeId + 'pretestGrade'}
                    label="Son Değerlendirme"
                    disabled={formRef?.current?.value[employee.employeeId + 'toogle'] !== 1}
                  />
                  <IconButton
                    type="button"
                    onClick={() => certificates(trainingDetail?.id, employee.employeeId, names[employee?.employeeId])}
                    disabled={formRef?.current?.value[employee.employeeId + 'toogle'] !== 1}>
                    <CardMembershipIcon />
                  </IconButton>
                </Grid>
              </>
            ))}
            <Grid md={12} lg={12} xs={12} sm={12} className="text-right mt-2">
              <Button type="submit" className="mr-4" variant="contained" color="primary">
                Bilgileri Kaydet
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Collapse>
    </>
  );
}
